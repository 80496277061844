<template>
    <div class="html">
        <div class="content">
            <h1 class="ribbon">Just a Rounded Ribbon</h1>
            <h2>Responsive? Resize the window.</h2>
            <p>Cupcake applicake jelly powder jelly-o tiramisu. Sweet roll marshmallow jujubes cheesecake I love I love
                brownie. I love gummies soufflé lemon drops wafer. Macaroon lollipop gummi bears gingerbread jelly-o
                pastry
                muffin. Tootsie roll powder biscuit I love cotton candy. I love cupcake marshmallow I love macaroon
                pastry
                danish.</p>
            <p>Lollipop I love chocolate liquorice soufflé. Croissant lollipop muffin I love sweet oat cake carrot cake.
                Macaroon gummi bears ice cream halvah. Pudding jelly beans sugar plum. Liquorice I love candy pudding
                soufflé sweet.</p>
            <p>Placeholder text from <a href="http://cupcakeipsum.com" target="_blank">cupcakeipsum.com</a></p>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'


export default {
    components: {
    },
    data() {
        return {
        }
    },
    setup() {
        return {
            icons: {
                mdiEyeOutline,
                mdiEyeOffOutline,
            },
        }
    },
}
</script>

<style lang="scss">
/*almost everything here is em based*/
@import url(https://fonts.googleapis.com/css?family=Crete+Round);

.html {
    font-size: 1em;
    background: #825582;
}

.content {
    background: #e6e2c8;
    width: 80%;
    max-width: 960px;
    min-height: 3.750em;
    margin: 2em auto;
    padding: 1.250em;
    border-radius: 0.313em;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    line-height: 1.5em;
    color: #292929;
}

.ribbon {
    position: relative;
    padding: 0 0.5em;
    font-size: 2.000em;
    margin: 0 0 0 -0.625em;
    line-height: 1.875em;
    color: #e6e2c8;
    border-radius: 0 0.156em 0.156em 0;
    background: rgb(123, 159, 199);
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
}

.ribbon:before,
.ribbon:after {
    position: absolute;
    content: '';
    display: block;
}

.ribbon:before {
    width: 0.469em;
    height: 100%;
    padding: 0 0 0.438em;
    top: 0;
    left: -0.469em;
    background: inherit;
    border-radius: 0.313em 0 0 0.313em;
}

.ribbon:after {
    width: 0.313em;
    height: 0.313em;
    background: rgba(0, 0, 0, 0.35);
    bottom: -0.313em;
    left: -0.313em;
    border-radius: 0.313em 0 0 0.313em;
    box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
    .ribbon {
        line-height: 1.143em;
        padding: 0.5em;
    }

    .ribbon:before,
    .ribbon:after {
        font-size: 0.714em;
    }
}
</style>
