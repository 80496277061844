<template>
    <div v-if="item" :class="'h-full pricing-card ' + (basicName === premnium ? 'secondary' : 'midmargin')">
        <div :class="basicName === premnium ? 'pricing-head-f' : 'pricing-head-t'">
            <span class="offer">{{ item[0].pricing.name }}</span>
            <h2 class="price" v-if="item[0].pricing">
                {{ item[0].pricing.price === 'Free' ? '' : '$' }}
                {{ item[0].pricing.price }}
            </h2>
            <span class="text-caption">Per month</span>
        </div>
        <v-divider class="mx-4 my-4"></v-divider>
        <div class="pricing-content mx-auto">
            <div class="pricing-item" v-for="(data, i) in item" :key="i">
                <v-icon size="1.1em" :color="'error'" class="p-icon-f" v-if="data.status === '0'">{{ icons.mdiClose }}
                </v-icon>
                <v-icon size="1.1em" :color="basicName === premnium ? 'secondaryAccent' : 'primary'" class="p-icon-t"
                    v-if="data.status === '1'">{{ icons.mdiCheck }}</v-icon>
                <span :class="basicName === premnium ? 'secondaryAccent--text' : 'accent--text' + 'p-text'">{{
                        data.roletype.designation
                }}</span>
            </div>
        </div>
        <v-divider class="mx-4 my-4 mx-auto"></v-divider>
        <div class="pricing-action mx-auto">
            <v-btn text outlined color="secondary" class="subsecondary" @click="initPurchaseSubscription"
                :disabled="basicName === basic">
                <span>subscribe</span>
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
        </div>
        <div >
            <v-card-text v-if="showLogInAlert === true">
                <div class="text-captio mx-auto">
                    <span class="error--text">
                        <v-icon small class="error--text">{{ icons.mdiAlertOctagonOutline }}</v-icon> You must
                    </span>
                    <router-link :to="{ name: 'login' }">logIn</router-link>
                </div>
            </v-card-text>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiAlertOctagonOutline, mdiClose, mdiCheck, mdiChevronRight } from '@mdi/js'
import { th } from 'vuetify/lib/locale'
import WalletRefillComponent from '@/views/pages/pricing/WalletRefillComponent.vue'


var md5 = require("md5")

export default {
    props: {
        item: null,
        level: 0,
    },
    components: {
        WalletRefillComponent,
    },
    data() {
        return {
            showLogInAlert: false,
            showSubmitedBtn: true,
            basic: 'basic',
            premnium: 'premnium',
            basicName: this.item[0].pricing.name.toLowerCase()
        }
    },
    methods: {
        initPurchaseSubscription() {
            if (!this.$store.getters.isLoggedIn) {
                this.showLogInAlert = true
            }
            if ((this.basicName !== this.basic) && (this.$store.getters.isLoggedIn)) {
                this.$store.commit("setInitSubscription", {
                    init: true,
                    data: this.item[0].pricing.name
                });
                console.log(this.$store.getters.getInitSubscription)
                this.$router.push({ name: 'subscription.purchase', params: { payement: md5(new Date()) } })
            }
        }
    },
    beforeMount() {
        if ((this.$store.getters.isLoggedIn) && (this.basicName === this.basic)) {
            this.showSubmitedBtn = false
        }
    },
    setup() {
        const icons = {
            mdiEyeOutline,
            mdiEyeOffOutline,
            mdiAlertOctagonOutline,

            mdiClose,
            mdiCheck,
            mdiChevronRight
        }
        return {
            icons
        }
    },
}
</script>

<style lang="scss">
.midmargin {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
}

.pricing-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    background-color: var(--v-subsecondary-base);
    border-radius: 0.8em;
    padding: 1em;
    text-align: center;
    width: min-content;
    height: max-content;

    .pricing-head-t {
        color: black;
    }

    .pricing-head-f {
        color: white;
    }

    .pricing-head,
    .pricing-head {

        .offer {
            font-weight: bold;
            font-size: 12pt;
        }

        .price {
            font-weight: bolder;
            font-size: 16pt;
        }
    }

    .pricing-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //align-items: center;
        width: max-content;
        height: 100%;


        .pricing-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            .p-icon-t {
                margin-right: 0.4em;
                font-size: 22pt;
                font-weight: bolder;
            }

            .p-icon-f {
                margin-right: 0.4em;
            }

            .p-text {
                font-size: 11pt;
            }
        }
    }

    .pricing-action {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}
</style>
