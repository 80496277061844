<template>
    <div v-if="item" :class="'h-full pricing-card ' + color.bg">
        <div :class="offer.title === offer.basic ? 'pricing-head-t' : 'pricing-head-f'">
            <span class="offer">{{ item[0].pricing.name }}</span>
            <h2 class="price" v-if="item[0].pricing">
                {{ item[0].pricing.price === 'Free' ? '' : '$' }}
                {{ item[0].pricing.price }}
            </h2>
            <span class="text-caption">Per month</span>
        </div>
        <v-divider class="mx-4 my-2"></v-divider>
        <div class="pricing-action">
            <v-btn text outlined :color="color.bg.toString()" :class="color.font.toString()"
                @click="initPurchaseSubscription" :disabled="offer.title === offer.basic">
                <span>subscribe</span>
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
        </div>
        <div v-if="showLogInAlert === true">
            <div class="text-captio mx-auto">
                <span class="error--text">
                    <v-icon small class="error--text">{{ icons.mdiAlertOctagonOutline }}</v-icon> You must
                </span>
                <router-link :to="{ name: 'login' }">logIn</router-link>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiAlertOctagonOutline, mdiClose, mdiCheck, mdiChevronRight } from '@mdi/js'
import { th } from 'vuetify/lib/locale'
import WalletRefillComponent from '@/views/pages/pricing/WalletRefillComponent.vue'


var md5 = require("md5")

export default {
    props: {
        item: null,
        level: 0,
    },
    components: {
        WalletRefillComponent,
    },
    data() {
        return {
            showLogInAlert: false,
            showSubmitedBtn: true,
            offer: {
                title: this.item[0].pricing.name.toLowerCase(),
                basic: 'basic',
                premnium: 'premnium',
                platine: 'platine',
            },
            color: {
                bg: "subsecondary",
                font: ""
            }
        }
    },
    methods: {
        setcolor() {
            if (this.offer.title === this.offer.premnium) {
                this.color.bg = 'secondary'
                this.color.font = 'subsecondary'
            }
            else if (this.offer.title === this.offer.platine) {
                this.color.bg = 'primary'
                this.color.font = 'subprimary'
            }
            else {
                this.color.bg = 'subsecondary'
                this.color.font = 'subsecondary'
            }
        },
        initPurchaseSubscription() {
            if (!this.$store.getters.isLoggedIn) {
                this.showLogInAlert = true
            }
            if ((this.offer.title !== this.offer.basic) && (this.$store.getters.isLoggedIn)) {
                this.$store.commit("setInitSubscription", {
                    init: true,
                    data: this.item[0].pricing.name
                });
                console.log(this.$store.getters.getInitSubscription)
                this.$router.push({ name: 'subscription.purchase', params: { payement: md5(new Date()) } })
            }
        }
    },
    beforeMount() {
        this.setcolor()
        if ((this.$store.getters.isLoggedIn) && (this.offer.title === this.offer.basic)) {
            this.showSubmitedBtn = false
        }
    },
    setup() {
        const icons = {
            mdiEyeOutline,
            mdiEyeOffOutline,
            mdiAlertOctagonOutline,

            mdiClose,
            mdiCheck,
            mdiChevronRight
        }
        return {
            icons
        }
    },
}
</script>

<style lang="scss">
.pricing-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--v-subsecondary-base);
    border-radius: 1em;
    padding: 1em;
    text-align: center;
    width: 100%;
    height: max-content;

    .pricing-head-t {
        color: black;
    }

    .pricing-head-f {
        color: white;
    }

    .pricing-head {

        .offer {
            font-weight: bold;
            font-size: 12pt;
        }

        .price {
            font-weight: bolder;
            font-size: 16pt;
        }
    }

    /*.pricing-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //align-items: center;
        width: max-content;
        height: 100%;


        .pricing-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            .p-icon-t {
                margin-right: 0.4em;
                font-size: 22pt;
                font-weight: bolder;
            }

            .p-icon-f {
                margin-right: 0.4em;
            }

            .p-text {
                font-size: 11pt;
            }
        }
    }*/

    .pricing-action {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}
</style>
