<template>
  <div class="secondaryAccent">
    <!-- VacanciesDetailCard -->
    <header-page :header="headers"></header-page>

    <v-container class="mx-auto">
      <v-row class="mx-auto px-4">
        <v-col class="text-center">
          <h1 class="primary--text">
            <!-- Offers plans for your profile -->
            Compare our offers plans
          </h1>
          <h3>Simple, transparent and Customized offers for unlimited access <br class="hidden-sm-and-down" />that
            grouws
            with you..!</h3>
          <!-- <h3>Simple, transparent princing that grouws with you</h3> -->
          <v-avatar size="2em" class="my-4">
            <v-icon color="white" class="secondary">
            </v-icon>
          </v-avatar>
        </v-col>
      </v-row>
      <v-row v-if="loaderdata === true" class="d-flex flex-row justify-center align-center">
        <v-col cols="12" md="3" v-for="i in 3" :key="i">
          <v-skeleton-loader v-bind="attrs"
            type="list-item-three-line, list-item-three-line, list-item-three-line, actions">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else class="d-flex flex-row justify-end align-center col-md-11">
        <v-spacer></v-spacer>
        <v-col cols="12" xm="11" sm="3" md="3" class="" v-for="(item_p, k) in pricings" :key="k">
          <pricing-head :item="item_p" :level="0"></pricing-head>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-row v-for="jj in pricings[0].length" :key="jj" class="secondary my-2 rounded">
            <v-spacer></v-spacer>
            <v-col cols="3" v-if="pricings[0]">
              <h3 class="secondaryAccent--text">
                {{ pricings[0][jj - 1].roletype.designation }}
              </h3>
            </v-col>
            <v-col cols="3" v-for="(price, i) in pricings" :key="i" class="d-flex justify-center align-center pa-2">
              <v-avatar size="2.8em" class="white my-3">
                <v-icon :color="'' + (colors(price[jj - 1])).bg + ''" size="1.4em" v-if="price[jj - 1].status === '1'">
                  {{ icons.mdiCheck }}
                </v-icon>
                <v-icon color="error" size="1.4em" v-else>{{ icons.mdiClose }} </v-icon>
              </v-avatar>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Some releases app -->
    <some-releases></some-releases>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCheck, mdiClose } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import FooterComponent from '@/layouts/components/FooterComponent.vue'
import HeaderComponent from '@/layouts/components/HeaderComponent.vue'
import { ref } from '@vue/composition-api'
import PricingCard from '@/views/pages/pricing/PricingCard.vue'
import PricingHead from '@/views/pages/pricing/PricingHead.vue'
import CardPost from '@/views/pages/components/CardPost.vue'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import SomeReleases from './components/SomeReleases.vue'

export default {
  components: {
    HeaderPage,
    PricingHead,
    HeaderComponent,
    FooterComponent,
    CardPost,
    SomeReleases
  },
  data() {
    return {
      headers: {
        titlepage: 'PRICING',
        icon: this.icons.mdiAccountGroup
      },
      loaderdata: true,
      pricings: [],
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      offer: {
        basic: 'basic',
        premnium: 'premnium',
        platine: 'platine',
      },
      color: {
        bg: "subsecondary",
        font: ""
      }
    }
  },
  methods: {
    colors(offer_) {
      // if (offer_.pricing.name.toLowerCase() === this.offer.premnium) {
      //   return {
      //     bg: 'secondary',
      //     font: 'subsecondary'
      //   }
      // }
      if (offer_.pricing.name.toLowerCase() === this.offer.platine) {
        return {
          bg: 'primary',
          font: 'subprimary'
        }
      }
      else {
        return {
          bg: 'secondary',
          font: 'subsecondary'
        }
      }
    },
  },
  beforeMount() {
    let tempRecipes = []
    let pricing_t = ['Basic', 'Premnium', 'Platine']
    this.loaderdata = true
    Drequest.api(`lazyloading.pricingrole?dfilter=on&next=1&per_page=all`)
      .get((response) => {
        if (response.success === true) {
          response.listEntity.forEach((element, index) => {
            tempRecipes.push(element)
          });
          pricing_t.forEach((it, k) => {
            this.pricings.push(tempRecipes.filter((item) => {
              return item.pricing.name.toUpperCase() == it.toUpperCase()
            }))
          })
          this.loaderdata = false
        }
        else {
          this.loaderdata = false
        }
      })
      .catch((err) => {
        this.loaderdata = false
      });
  },
  computed: {
    sortPricing() {
      let tempRecipes = this.pricings
      let tempPricing = {
        basic: [],
        premnium: [],
        platine: [],
      }
      tempPricing.basic = this.pricings[0]
      return tempPricing
    }
  },
  setup() {
    return {
      icons: {
        mdiCheck, mdiClose
      },
    }
  },
}
</script>

<style lang="scss">
//@import '~@/styles/main-style.scss';
@import '~@/styles/utils-style.scss';

//@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
.ddd {
  border: solid 1px #000;
}
</style>
